import React, { createContext, useState, useContext } from 'react';

const TabContext = createContext();

export const TabProvider = ({ children }) => {
 const [activeTabIndex, setActiveTabIndex] = useState(0);

 return (
    <TabContext.Provider value={{ activeTabIndex, setActiveTabIndex }}>
      {children}
    </TabContext.Provider>
 );
};

export const useTabContext = () => useContext(TabContext);